import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';

import Mapbox from '../../mapbox'
import Calculate from '../../util/calculate';

import Sidetab from '../../components/Sidetab'

import Chart from '../../components/Chart';
import '../index.css';
import { Select, Divider } from 'antd';
import Filter from './FilterDisadvantaged';
import ModalTabelByProvicce from '../../components/modal/ModalTabelByProvicce';
import ModalTabelBySungkud from '../../components/modal/ModalTabelBySungkud';

import confixColumnsTable from '../../confixTable';
import styled from 'styled-components'
import ModalQinfo from '../../components/modal/ModalQinfo';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import {
  InfoCircleOutlined,
  TableOutlined
} from '@ant-design/icons';

import TumbonAddr from '../../util/TumbonAddr'
import api from '../../util/api';

const { Option } = Select;

const styleCard = {
  borderRadius: 12,
  padding: '12px',
  margin: '18px 12px 18px 12px',
  backgroundColor: '#fff',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  display: 'flex',
  flexDirection: 'column',
}

const Title = styled.div`
  color: #333;
  font-size: ${props => (props.fontSize ? props.fontSize : "1.20rem")}; 
  font-family: Kanit;
  text-align: center;
`

const KEY_WELFARE = ['welfareID_abandoned', 'welfareID_aids', 'welfareID_child_abuse', 'welfareID_detention_juvenile',
  'welfareID_drug', 'welfareID_family', 'welfareID_forced', 'welfareID_jon', 'welfareID_minority', 'welfareID_orphan',
  'welfareID_other', 'welfareID_sex_buiness', 'welfareID_street_children']
const KEY_DISABILITY = ['disability_autism', 'disability_behavioral',
  'disability_double', 'disability_hearing',
  'disability_intellectually', 'disability_learning', 'disability_physically',
  'disability_speech', 'disability_visually']

const checkValue = (value) => (value ? value : 0)
const mapData = (data, filter) => {

  const _filterData = (x) => {
    if (filter.view === 'province') return (x.province_name === filter.province_name)
    else if (filter.view === 'city') return (x.province_name === filter.province_name && x.city_name === filter.city_name)
    else if (filter.view === 'tumbon') return (x.province_name === filter.province_name && x.city_name === filter.city_name && x.tumbon_name === filter.tumbon_name)
    else return true
  }

  let result = {
    studentsDisability: 0,
    studentsDisabvantaged: 0,
    disability: [
      { name: 'บุคคลที่มีความบกพร่องทางการเรียนรู้', value: 0 },
      { name: 'บุคคลที่มีความบกพร่องทางสติปัญญา', value: 0 },
      { name: 'บุคคลที่มีความบกพร่องทางร่างกายหรือสุขภาพ', value: 0 },
      { name: 'บุคคลพิการซ้อน', value: 0 },
      { name: 'บุคคลที่มีความบกพร่องทางพฤติกรรมหรืออารมณ์', value: 0 },
      { name: 'บุคคลออทิสติก', value: 0 },
      { name: 'บุคคลที่มีความบกพร่องทางการพูดเเละภาษา', value: 0 },
      { name: 'บุคคลที่มีความบกพร่องทางการมองเห็น', value: 0 },
      { name: 'บุคคลที่มีความบกพร่องทางการได้ยิน', value: 0 }
    ],
    disabilityCharts: [{
      name: 'จำนวนนักเรียนพิการ',
      colorByPoint: true,
      data: [
        { name: '', y: 0, color: '#FA913D' },
        { name: '', y: 0, color: '#EBE673' },
        { name: '', y: 0, color: '#FAD223' },
        { name: '', y: 0, color: '#E1F5C3' },
        { name: '', y: 0, color: '#FF5050' },
        { name: '', y: 0, color: '#FAAF96' },
        { name: '', y: 0, color: '#BE6E82' },
        { name: '', y: 0, color: '#6E5A7D' },
        { name: '', y: 0, color: '#73735A' },
        // { name: '', y: 0, color: '#FFC000' },
        // { name: '', y: 0, color: '#FFD657' },
        // { name: '', y: 0, color: '#FFE79C' },
        // { name: '', y: 0, color: '#743000' },
        // { name: '', y: 0, color: '#D2600E' },
        // { name: '', y: 0, color: '#743000' },
        // { name: '', y: 0, color: '#D2600E' },
        // { name: '', y: 0, color: '#743000' },
        // { name: '', y: 0, color: '#743000' },
      ]
    }],
    disadvantaged: [
      { name: 'เด็กยากจน', value: 0 },
      { name: 'ชนกลุ่มน้อย', value: 0 },
      { name: 'เด็กถูกทอดทิ้ง/กำพร้า', value: 0 },

      { name: 'เด็กที่ถูกทำร้ายทารุณ', value: 0 },
      { name: 'เด็กเร่ร่อน', value: 0 },
      { name: 'เด็กถูกบังคับให้ขายเเรงงาน', value: 0 },
      { name: 'เด็กที่มีปัญหาเกี่ยวกับยาเสพติด', value: 0 },
      { name: 'เด็กในสถานพินิจและคุ้มครองเด็กเยาวชน', value: 0 },

      { name: 'ผลกระทบจากเอดส์', value: 0 },
      { name: 'เด็กที่อยู่ในธุรกิจทางเพศ', value: 0 },
    ],
    disadvantagedCharts: [{
      name: 'จำนวนนักเรียนด้อยโอกาส',
      colorByPoint: true,
      data: [
        { name: '', y: 0, color: '#FFC000' },
        { name: '', y: 0, color: '#743000' },
        { name: '', y: 0, color: '#D2600E' },

        { name: '', y: 0, color: '#FFC000' },
        { name: '', y: 0, color: '#FFD657' },
        { name: '', y: 0, color: '#FFE79C' },
        { name: '', y: 0, color: '#743000' },
        { name: '', y: 0, color: '#D2600E' },

        { name: '', y: 0, color: '#FFC000' },
        { name: '', y: 0, color: '#FFD657' },

      ]
    }],
    studentJonAndExjon: [{
      name: 'ข้อมูลจำนวนโรงเรียนแบ่งตามระดับชั้น',
      colorByPoint: true,
      data: []
    }],
    studentJonAndExjonGender: [{
      name: 'ข้อมูลจำนวนโรงเรียนแบ่งตามระดับชั้น',
      colorByPoint: true,
      data: []
    }],
    studentJonAndExjonProvince: [
      {
        name: 'ชาย',
        color: '#93c5fd',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        stack: 'student'
      }, {
        name: 'หญิง',
        color: '#f9a8d4',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        stack: 'student'
      }
    ],
    disabilityByClass: [{
      name: 'ก่อนประถมศึกษา',
      data: [0],
      color: '#a5f3fc'
    }, {
      name: 'ประถมศึกษาตอนต้น',
      data: [0],
      color: '#b4fca5'
    },  {
      name: 'ประถมศึกษาตอนปลาย',
      data: [0],
      color: '#a5b4fc'
    }, {
      name: 'มัธยมต้น',
      data: [0],
      color: '#fbcfe8'
    }, {
      name: 'มัธยมปลาย',
      data: [0],
      color: '#fda4af'
    }]
  }


  data
    .filter(_filterData)
    .forEach(x => {
      // + x[`studentJonWomen${year}`])
      let year = filter.year
      result.studentJonAndExjonProvince[0].data[0] += checkValue(x[`disability_hearing_m${year}`])
      result.studentJonAndExjonProvince[0].data[1] += checkValue(x[`disability_intellectually_m${year}`])
      result.studentJonAndExjonProvince[0].data[2] += checkValue(x[`disability_behavioral_m${year}`])
      result.studentJonAndExjonProvince[0].data[3] += checkValue(x[`disability_autism_m${year}`])
      result.studentJonAndExjonProvince[0].data[4] += checkValue(x[`disability_speech_m${year}`])
      result.studentJonAndExjonProvince[0].data[5] += checkValue(x[`disability_visually_m${year}`])
      result.studentJonAndExjonProvince[0].data[6] += checkValue(x[`disability_learning_m${year}`])
      result.studentJonAndExjonProvince[0].data[7] += checkValue(x[`disability_physically_m${year}`])
      result.studentJonAndExjonProvince[0].data[8] += checkValue(x[`disability_double_m${year}`])

      result.studentJonAndExjonProvince[1].data[0] += checkValue(x[`disability_hearing_f${year}`])
      result.studentJonAndExjonProvince[1].data[1] += checkValue(x[`disability_intellectually_f${year}`])
      result.studentJonAndExjonProvince[1].data[2] += checkValue(x[`disability_behavioral_f${year}`])
      result.studentJonAndExjonProvince[1].data[3] += checkValue(x[`disability_autism_f${year}`])
      result.studentJonAndExjonProvince[1].data[4] += checkValue(x[`disability_speech_f${year}`])
      result.studentJonAndExjonProvince[1].data[5] += checkValue(x[`disability_visually_f${year}`])
      result.studentJonAndExjonProvince[1].data[6] += checkValue(x[`disability_learning_f${year}`])
      result.studentJonAndExjonProvince[1].data[7] += checkValue(x[`disability_physically_f${year}`])
      result.studentJonAndExjonProvince[1].data[8] += checkValue(x[`disability_double_f${year}`])

      result.disability[0].value += (checkValue(x[`disability_learning_m${year}`]) + checkValue(x[`disability_learning_f${year}`]))
      result.disability[1].value += (checkValue(x[`disability_intellectually_m${year}`]) + checkValue(x[`disability_intellectually_f${year}`]))
      result.disability[2].value += (checkValue(x[`disability_physically_m${year}`]) + checkValue(x[`disability_physically_f${year}`]))
      result.disability[3].value += (checkValue(x[`disability_double_m${year}`]) + checkValue(x[`disability_double_f${year}`]))
      result.disability[4].value += (checkValue(x[`disability_behavioral_m${year}`]) + checkValue(x[`disability_behavioral_f${year}`]))
      result.disability[5].value += (checkValue(x[`disability_autism_m${year}`]) + checkValue(x[`disability_autism_f${year}`]))
      result.disability[6].value += (checkValue(x[`disability_speech_m${year}`]) + checkValue(x[`disability_speech_f${year}`]))
      result.disability[7].value += (checkValue(x[`disability_visually_m${year}`]) + checkValue(x[`disability_visually_f${year}`]))
      result.disability[8].value += (checkValue(x[`disability_hearing_m${year}`]) + checkValue(x[`disability_hearing_f${year}`]))


      result.disadvantaged[0].value += checkValue(x[`welfareID_jon${year}`])
      result.disadvantaged[1].value += checkValue(x[`welfareID_minority${year}`])
      result.disadvantaged[2].value += checkValue(x[`welfareID_abandoned${year}`]) + checkValue(x[`welfareID_orphan${year}`])

      result.disadvantaged[3].value += checkValue(x[`welfareID_child_abuse${year}`])
      result.disadvantaged[4].value += checkValue(x[`welfareID_street_children${year}`])
      result.disadvantaged[5].value += checkValue(x[`welfareID_forced${year}`])
      result.disadvantaged[6].value += checkValue(x[`welfareID_drug${year}`])

      result.disadvantaged[7].value += checkValue(x[`welfareID_detention_juvenile${year}`])
      result.disadvantaged[8].value += checkValue(x[`welfareID_aids${year}`])
      result.disadvantaged[9].value += checkValue(x[`welfareID_sex_buiness${year}`])

      result.disabilityByClass[0].data[0] += checkValue(x[`k_disability${year}`])
      result.disabilityByClass[1].data[0] += checkValue(x[`p_disability${year}`])
      result.disabilityByClass[2].data[0] += checkValue(x[`up_disability${year}`])
      result.disabilityByClass[3].data[0] += checkValue(x[`mt_disability${year}`])
      result.disabilityByClass[4].data[0] += checkValue(x[`hi_disability${year}`])

    })

  result.disability = result.disability.sort((a, b) => b.value - a.value)
  result.disabilityCharts[0].data[0].name = result.disability[0].name
  result.disabilityCharts[0].data[0].y = result.disability[0].value
  result.disabilityCharts[0].data[1].name = result.disability[1].name
  result.disabilityCharts[0].data[1].y = result.disability[1].value
  result.disabilityCharts[0].data[2].name = result.disability[2].name
  result.disabilityCharts[0].data[2].y = result.disability[2].value
  result.disabilityCharts[0].data[3].name = result.disability[3].name
  result.disabilityCharts[0].data[3].y = result.disability[3].value
  result.disabilityCharts[0].data[4].name = result.disability[4].name
  result.disabilityCharts[0].data[4].y = result.disability[4].value
  result.disabilityCharts[0].data[5].name = result.disability[5].name
  result.disabilityCharts[0].data[5].y = result.disability[5].value
  result.disabilityCharts[0].data[6].name = result.disability[6].name
  result.disabilityCharts[0].data[6].y = result.disability[6].value
  result.disabilityCharts[0].data[7].name = result.disability[7].name
  result.disabilityCharts[0].data[7].y = result.disability[7].value
  result.disabilityCharts[0].data[8].name = result.disability[8].name
  result.disabilityCharts[0].data[8].y = result.disability[8].value



  result.disadvantaged = result.disadvantaged.sort((a, b) => b.value - a.value)
  result.disadvantagedCharts[0].data[0].name = result.disadvantaged[0].name
  result.disadvantagedCharts[0].data[0].y = result.disadvantaged[0].value
  result.disadvantagedCharts[0].data[1].name = result.disadvantaged[1].name
  result.disadvantagedCharts[0].data[1].y = result.disadvantaged[1].value
  result.disadvantagedCharts[0].data[2].name = result.disadvantaged[2].name
  result.disadvantagedCharts[0].data[2].y = result.disadvantaged[2].value

  result.disadvantagedCharts[0].data[3].name = result.disadvantaged[3].name
  result.disadvantagedCharts[0].data[3].y = result.disadvantaged[3].value
  result.disadvantagedCharts[0].data[4].name = result.disadvantaged[4].name
  result.disadvantagedCharts[0].data[4].y = result.disadvantaged[4].value
  result.disadvantagedCharts[0].data[5].name = result.disadvantaged[5].name
  result.disadvantagedCharts[0].data[5].y = result.disadvantaged[5].value
  result.disadvantagedCharts[0].data[6].name = result.disadvantaged[6].name
  result.disadvantagedCharts[0].data[6].y = result.disadvantaged[6].value
  result.disadvantagedCharts[0].data[7].name = result.disadvantaged[7].name
  result.disadvantagedCharts[0].data[7].y = result.disadvantaged[7].value

  result.disadvantagedCharts[0].data[8].name = result.disadvantaged[8].name
  result.disadvantagedCharts[0].data[8].y = result.disadvantaged[8].value
  result.disadvantagedCharts[0].data[9].name = result.disadvantaged[9].name
  result.disadvantagedCharts[0].data[9].y = result.disadvantaged[9].value
  
  return (result)

}

const mapDataTable = (typessssd, data, year = 'all') => {
  if (typessssd === 'table1') {
    return (data.map(_data => {
      return ({
        "welfareID_forced": checkValue(_data[`welfareID_forced${year}`]),
        "welfareID_minority": checkValue(_data[`welfareID_minority${year}`]),
        "welfareID_sex_buiness": checkValue(_data[`welfareID_sex_buiness${year}`]),
        "welfareID_abandoned": checkValue(_data[`welfareID_abandoned${year}`]) + checkValue(_data[`welfareID_orphan${year}`]),
        "welfareID_detention_juvenile": checkValue(_data[`welfareID_detention_juvenile${year}`]),
        "welfareID_street_children": checkValue(_data[`welfareID_street_children${year}`]),
        "welfareID_aids": checkValue(_data[`welfareID_aids${year}`]),
        "welfareID_child_abuse": checkValue(_data[`welfareID_child_abuse${year}`]),
        "welfareID_jon": checkValue(_data[`welfareID_jon${year}`]),
        "welfareID_drug": checkValue(_data[`welfareID_drug${year}`]),
        "welfareID_other": checkValue(_data[`welfareID_other${year}`]),
        "province_name": _data.province_name,
        "city_name": _data.city_name,
        "tumbon_name": _data.tumbon_name,
        "sungkud": _data.sungkud,
        "area": _data.area,
      })
    }))
  } else if (typessssd === 'table2') {
    return (data.map(_data => {
      return ({
        "disability_learning": checkValue(_data[`disability_learning_m${year}`]) + checkValue(_data[`disability_learning_f${year}`]),
        "disability_intellectually": checkValue(_data[`disability_intellectually_m${year}`]) + checkValue(_data[`disability_intellectually_f${year}`]),
        "disability_physically": checkValue(_data[`disability_physically_m${year}`]) + checkValue(_data[`disability_physically_f${year}`]),
        "disability_double": checkValue(_data[`disability_double_m${year}`]) + checkValue(_data[`disability_double_f${year}`]),
        "disability_behavioral": checkValue(_data[`disability_behavioral_m${year}`]) + checkValue(_data[`disability_behavioral_f${year}`]),
        "disability_autism": checkValue(_data[`disability_autism_m${year}`]) + checkValue(_data[`disability_autism_f${year}`]),
        "disability_speech": checkValue(_data[`disability_speech_m${year}`]) + checkValue(_data[`disability_speech_f${year}`]),
        "disability_visually": checkValue(_data[`disability_visually_m${year}`]) + checkValue(_data[`disability_visually_f${year}`]),
        "disability_hearing": checkValue(_data[`disability_hearing_m${year}`]) + checkValue(_data[`disability_hearing_f${year}`]),
        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table3') {
    return (data.map(_data => {
      let disability_total = (
        checkValue(_data[`k_disability${year}`]) +
        checkValue(_data[`p_disability${year}`]) +
        checkValue(_data[`up_disability${year}`]) +
        checkValue(_data[`mt_disability${year}`]) +
        checkValue(_data[`hi_disability${year}`])
      )
      return ({
        disability_total: disability_total,
        k_disability: checkValue(_data[`k_disability${year}`]),
        p_disability: checkValue(_data[`p_disability${year}`]),
        up_disability: checkValue(_data[`up_disability${year}`]),
        mt_disability: checkValue(_data[`mt_disability${year}`]),
        hi_disability: checkValue(_data[`hi_disability${year}`]),
        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table5') {
    return data
      .map(_data => {
        let allJonMen = (
          _data[`studentJonMen${year}`] +
          _data[`studentJonWomen${year}`] +
          _data[`studentExJonMen${year}`] +
          _data[`studentExJonWomen${year}`]
        )

        return ({
          studentTotal: _data[`studentMen${year}`] + _data[`studentWomen${year}`],
          studentJon: _data[`studentJonMen${year}`] + _data[`studentJonWomen${year}`],
          studentExJon: _data[`studentExJonMen${year}`] + _data[`studentExJonWomen${year}`],
          studentJonAndExJon: allJonMen,
          province_name: _data.province_name,
          city_name: _data.city_name,
          tumbon_name: _data.tumbon_name,
          sungkud: _data.sungkud,
          area: _data.area,
        })
      })
  } else if (typessssd === 'table6') {
    return data
      .map(_data => {
        let allJonMen = (
          _data[`studentJonMen${year}`] +
          _data[`studentJonWomen${year}`] +
          _data[`studentExJonMen${year}`] +
          _data[`studentExJonWomen${year}`]
        )

        return ({
          studentTotal: _data[`studentMen${year}`] + _data[`studentWomen${year}`],
          studentJondmc: _data[`studentJonMen${year}`] + _data[`studentJonWomen${year}`],
          studentJon: _data[`studentJonMen${year}`] + _data[`studentJonWomen${year}`],
          studentExJon: _data[`studentExJonMen${year}`] + _data[`studentExJonWomen${year}`],
          studentJonAndExJon: allJonMen,
          province_name: _data.province_name,
          city_name: _data.city_name,
          tumbon_name: _data.tumbon_name,
          sungkud: _data.sungkud,
          area: _data.area,
        })
      })
  }
}

export default function StuentJon(props) {
  const [initData, setInitData] = useState(null)
  const [filter, setFilter] = useState({
    type: 'all',
    // view: props.pageContext.view,
    view: 'province',
    year: '2566',
    province_name: null,
    city_name: null,
    tumbon_name: null,
    sungkud: null,
    area: null,
  })
  const [school, setSchool] = useState({
    studentJonAndExjon: [],
  });
  const [open, setOpen] = useState(true)
  const [open_map_filter, setOpenMapFilter] = useState(true)
  const [map_type, setMapType] = useState('ทั้งหมด')
  const [tabvalue, setTabValue] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data_map, setDataMap] = useState([])
  const [dataTable, setDataTable] = useState([]);
  const [title, setTitle] = useState('');
  const [isModalQinfoVisible, setIsModalQinfoVisible] = useState(false);
  const [qinfo, setQinfo] = useState({
    description: '',
    refs: []
  });
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("disadvantaged-disabled");
    setInitData(dataSummaryAllyear.data);
  }, [])

  useEffect(async () => {
    console.log('initData',initData)
    if (initData) {
      let user_data = await api.getProfileDb()
      let province = null
      if (user_data.ok) {
        let user_province_id = '10'
        if (user_data.profile.profile.provinceId) {
          province = TumbonAddr.find(item => item.provinceid === user_data.profile.profile.provinceId).provincename
        }
        filter.province_name = province
        setFilter({ ...filter })
        setSchool(mapData(initData, filter))
      }
    }
  }, [initData]);

  useEffect(() => {
    if (initData) {
      if (filter.province_name) {
        let response = onProcessData({
          province: filter['province_name'],
          city: filter['city_name'],
          tumbon: filter['tumbon_name'],
          map_type: map_type,
          layer: filter.view
        }, convertMapContent(filter.view, initData))
        console.log('response',response)
        setDataMap([
          { id: 'border', layer: filter.view, data: response.data },
        ])
        setSchool(mapData(initData, filter))
      }
    }
  }, [filter, map_type, initData]);

  const convertMapContent = (layer, data) => {
    let res = []
    let key_map = tabvalue === 0 ? KEY_WELFARE.reduce((acc, curr) => (acc[curr] = 0, acc), {}) : KEY_DISABILITY.reduce((acc, curr) => (acc[curr] = 0, acc), {})
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { province: item, ...key_map } });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], ...key_map } });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], ...key_map } });
    let year = filter.year

    data.forEach((item) => {
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (index !== -1) {

          if (tabvalue === 0) {
            for (let i = 0; i < KEY_WELFARE.length; i++) {
              !province[index]['total'] ? province[index]['total'] = item[KEY_WELFARE[i] + year] : province[index]['total'] += item[KEY_WELFARE[i] + year]
              !province[index][KEY_WELFARE[i]] ? province[index][KEY_WELFARE[i]] = item[KEY_WELFARE[i] + year] : province[index][KEY_WELFARE[i]] += item[KEY_WELFARE[i] + year]
            }
          } else {
            for (let i = 0; i < KEY_DISABILITY.length; i++) {
              ['_f', '_m'].forEach(g => {
                !province[index]['total'] ? province[index]['total'] = item[KEY_DISABILITY[i] + g + year] : province[index]['total'] += item[KEY_DISABILITY[i] + g + year]
                !province[index][KEY_DISABILITY[i]] ? province[index][KEY_DISABILITY[i]] = item[KEY_DISABILITY[i] + g + year] : province[index][KEY_DISABILITY[i]] += item[KEY_DISABILITY[i] + g + year]
              })
            }
          }

        }
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          if (tabvalue === 0) {
            for (let i = 0; i < KEY_WELFARE.length; i++) {
              !city[index]['total'] ? city[index]['total'] = item[KEY_WELFARE[i] + year] : city[index]['total'] += item[KEY_WELFARE[i] + year]
              !city[index][KEY_WELFARE[i]] ? city[index][KEY_WELFARE[i]] = item[KEY_WELFARE[i] + year] : city[index][KEY_WELFARE[i]] += item[KEY_WELFARE[i] + year]
            }
          } else {
            for (let i = 0; i < KEY_DISABILITY.length; i++) {
              ['_f', '_m'].forEach(g => {
                !city[index]['total'] ? city[index]['total'] = item[KEY_DISABILITY[i] + g + year] : city[index]['total'] += item[KEY_DISABILITY[i] + g + year]
                !city[index][KEY_DISABILITY[i]] ? city[index][KEY_DISABILITY[i]] = item[KEY_DISABILITY[i] + g + year] : city[index][KEY_DISABILITY[i]] += item[KEY_DISABILITY[i] + g + year]
              })
            }
          }
        }
        res = city
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          if (tabvalue === 0) {
            for (let i = 0; i < KEY_WELFARE.length; i++) {
              !tumbon[index]['total'] ? tumbon[index]['total'] = item[KEY_WELFARE[i] + year] : tumbon[index]['total'] += item[KEY_WELFARE[i] + year]
              !tumbon[index][KEY_WELFARE[i]] ? tumbon[index][KEY_WELFARE[i]] = item[KEY_WELFARE[i] + year] : tumbon[index][KEY_WELFARE[i]] += item[KEY_WELFARE[i] + year]
            }
          } else {
            for (let i = 0; i < KEY_DISABILITY.length; i++) {
              ['_f', '_m'].forEach(g => {
                !tumbon[index]['total'] ? tumbon[index]['total'] = item[KEY_DISABILITY[i] + g + year] : tumbon[index]['total'] += item[KEY_DISABILITY[i] + g + year]
                !tumbon[index][KEY_DISABILITY[i]] ? tumbon[index][KEY_DISABILITY[i]] = item[KEY_DISABILITY[i] + g + year] : tumbon[index][KEY_DISABILITY[i]] += item[KEY_DISABILITY[i] + g + year]
              })
            }
          }
        }
        res = tumbon
      } else if (layer === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          if (tabvalue === 0) {
            for (let i = 0; i < KEY_WELFARE.length; i++) {
              !tumbon[index]['total'] ? tumbon[index]['total'] = item[KEY_WELFARE[i] + year] : tumbon[index]['total'] += item[KEY_WELFARE[i] + year]
              !tumbon[index][KEY_WELFARE[i]] ? tumbon[index][KEY_WELFARE[i]] = item[KEY_WELFARE[i] + year] : tumbon[index][KEY_WELFARE[i]] += item[KEY_WELFARE[i] + year]
            }
          } else {
            for (let i = 0; i < KEY_DISABILITY.length; i++) {
              ['_f', '_m'].forEach(g => {
                !tumbon[index]['total'] ? tumbon[index]['total'] = item[KEY_DISABILITY[i] + g + year] : tumbon[index]['total'] += item[KEY_DISABILITY[i] + g + year]
                !tumbon[index][KEY_DISABILITY[i]] ? tumbon[index][KEY_DISABILITY[i]] = item[KEY_DISABILITY[i] + g + year] : tumbon[index][KEY_DISABILITY[i]] += item[KEY_DISABILITY[i] + g + year]
              })
            }
          }
        }
        res = tumbon
      }
    })
    return res
  }

  const onProcessData = (filter, data) => {
    let key = (map_type === 'ทั้งหมด' ? 'total' : map_type)
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element[key] / element[key])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element[key] / result.map(item => item.tps).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item[key])), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item[key])), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item[key])), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element[key])
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  let tab_content = [
    {
      title: 'เด็กนักเรียนด้อยโอกาส',
      content: <>
        <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, flexDirection: 'row' }} >
          <Filter
            optionYear={[
              { value: '2566', text: '2566' },
              { value: '2565', text: '2565' },
              { value: '2564', text: '2564' },
              { value: '2563', text: '2563' },
            ]}
            optionSungkud={[
              { value: null, text: 'ทั้งหมด' },
              { value: 'สพฐ.', text: 'สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ.)' },
              { value: 'อปท.', text: 'กรมส่งเสริมการปกครองท้องถิ่น (อปท.)' },
              { value: 'ตชด.', text: 'กองบัญชาการตำรวจตระเวนชายแดน (ตชด.)' },
              { value: 'พศ.', text: 'สำนักงานพระพุทธศาสนาแห่งชาติ (พศ.)' },
              { value: 'สช.', text: 'สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน (สช.)' },
              { value: 'กทม.', text: 'สำนักการศึกษา กรุงเทพมหานคร (กทม.)' },
            ]}
            data={filter}
            onChange={(data_filter) => {
              setFilter({
                ...filter,
                ...data_filter
              })
            }}
          />
        </div>

        <div className="code-box-shape4 queue-anim-demo p-[4px] my-[10px] lg:p-[12px] lg:mx-[18px] lg:my-[12px] bg-white flex flex-col h-min rounded-lg drop-shadow-xl"  >
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '72px' }}> </div>
            <div>
              <Title>ประเภทนักเรียนด้อยโอกาส</Title>
              <Title fontSize={"0.8rem"} > ปีการศึกษา {filter.year}</Title>
            </div>
            <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                  showModal()
                  setColumns(confixColumnsTable.disadvantaged)
                  setDataTable(mapDataTable('table1', initData, filter.year))
                  setTitle(`ข้อมูลจำนวนเด็กด้อยโอกาส ปีการศึกษา${filter.year}`)
                }} />
              </div>
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                  onClick={() => {
                    setQinfo({
                      description: ` นักเรียนจะถูกจำแนกออกตามประเภทของความด้อยโอกาส ไม่ว่าจะเป็นเด็กยากจน เด็กกำพร้า ชนกลุ่มน้อย และอื่นๆ สำหรับนักเรียนพิการก็เช่นกันที่จำเป็นต้องจำแนกออกไปตามประเภทของความพิการ เช่น บกพร่องทางการเรียนรู้ บกพร่องทางสติปัญญา บกพร่องทางร่างกายหรือสุขภาพ และอื่น ๆ`,
                      refs: [
                        'จำนวนนักเรียนพิการจากข้อมูล DMC ปีการศึกษา 2563-2564',
                      ]
                    });
                    setIsModalQinfoVisible(true)
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 28 }}>
            <Chart
              options={{
                title: null,
                type: 'pie',
              }}
              click={({ e }) => {
                showModal()
                setColumns(confixColumnsTable.disadvantaged)
                setDataTable(mapDataTable('table1', initData, filter.year))
                setTitle(`ข้อมูลจำนวนเด็กด้อยโอกาส ปีการศึกษา${filter.year}`)
              }}
              containerProps={{ style: { height: "350px" } }}
              style={{ width: '100px', height: '350px' }}
              data={school.disadvantagedCharts}
            />


          </div>
        </div>
        <div className="code-box-shape4 queue-anim-demo p-[4px] my-[10px] lg:p-[12px] lg:mx-[18px] lg:my-[12px] bg-white flex flex-col h-min rounded-lg drop-shadow-xl"  >
          <div style={{ width: '100%', flexDirection: 'column', display: 'flex', justifyItems: 'center', padding: '5%', paddingTop: '4px' }}>
            <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
              <div style={{ fontSize: '1.20rem', color: '#333', fontFamily: 'Kanit' }}>{`ประเภทนักเรียนด้อยโอกาส`}</div>
              <div style={{ fontSize: '1.10rem', color: '#333', fontFamily: 'Kanit' }}> {`จำนวน(คน)`} </div>
            </div>
            {
              school?.disadvantaged
                ?.sort((a, b) => b.value - a.value)
                .map(x => (
                  <>
                    <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', display: 'flex', justifyItems: 'center' }}>
                      <div style={{ fontSize: '1rem', color: '#333', fontFamily: 'Kanit', marginLeft: 12 }}>{x.name}</div>
                      <div style={{ fontSize: '1rem', color: '#FAAB01', fontFamily: 'Kanit' }}> {x.value.toLocaleString('en-US')} </div>
                    </div>
                    <Divider style={{ margin: 0 }} />
                  </>)
                )
            }
          </div>
        </div>
      </>
    },
    {
      title: 'เด็กนักเรียนพิการ',
      content: <>
        <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, flexDirection: 'row' }} >
          <Filter
            optionYear={[
              { value: '2566', text: '2566' },
              { value: '2565', text: '2565' },
              { value: '2564', text: '2564' },
              { value: '2563', text: '2563' },
            ]}
            optionSungkud={[
              { value: null, text: 'ทั้งหมด' },
              { value: 'สพฐ.', text: 'สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ.)' },
              { value: 'อปท.', text: 'กรมส่งเสริมการปกครองท้องถิ่น (อปท.)' },
              { value: 'ตชด.', text: 'กองบัญชาการตำรวจตระเวนชายแดน (ตชด.)' },
              { value: 'พศ.', text: 'สำนักงานพระพุทธศาสนาแห่งชาติ (พศ.)' },
              { value: 'สช.', text: 'สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน (สช.)' },
              { value: 'กทม.', text: 'สำนักการศึกษา กรุงเทพมหานคร (กทม.)' },
            ]}
            data={filter}
            onChange={(data_filter) => {
              setFilter({
                ...filter,
                ...data_filter
              })
            }}
          />
        </div>
        <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '450px' }} >
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '72px' }}> </div>
            <div>
              <Title>ประเภทนักเรียนพิการ</Title>
              <Title fontSize={"0.8rem"} > ปีการศึกษา {filter.year}</Title>
            </div>
            <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                  showModal()
                  setColumns(confixColumnsTable.disabled)
                  setDataTable(mapDataTable('table2', initData, filter.year))
                  setTitle(`ประเภทนักเรียนพิการ ปีการศึกษา${filter.year}`)
                }} />
              </div>
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                  onClick={() => {
                    setQinfo({
                      description: ` นักเรียนจะถูกจำแนกออกตามประเภทของความด้อยโอกาส ไม่ว่าจะเป็นเด็กยากจน เด็กกำพร้า ชนกลุ่มน้อย และอื่นๆ สำหรับนักเรียนพิการก็เช่นกันที่จำเป็นต้องจำแนกออกไปตามประเภทของความพิการ เช่น บกพร่องทางการเรียนรู้ บกพร่องทางสติปัญญา บกพร่องทางร่างกายหรือสุขภาพ และอื่น ๆ`,
                      refs: [
                        'จำนวนนักเรียนพิการจากข้อมูล DMC ปีการศึกษา 2563-2564',
                      ]
                    });
                    setIsModalQinfoVisible(true)
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 28 }}>
            <Chart
              options={{
                title: null,
                type: 'pie',
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      // formatter: function () {
                      //   return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                      // },
                      allowOverlap: true,
                      crop: false,
                      enabled: true,
                    },
                  }
                },

              }}

              containerProps={{ style: { height: "350px" } }}
              style={{ width: '100px', height: '350px' }}
              data={school.disabilityCharts}
              click={({ e }) => {
                showModal()
                setColumns(confixColumnsTable.disabled)
                setDataTable(mapDataTable('table2', initData, filter.year))
                setTitle(`ประเภทนักเรียนพิการ ปีการศึกษา${filter.year}`)
              }}
            />

          </div>
        </div>
        <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '350px' }} >
          <div style={{ width: '100%', flexDirection: 'column', display: 'flex', justifyItems: 'center', padding: '5%', paddingTop: '4px' }}>
            <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
              <div style={{ fontSize: '1.20rem', color: '#333', fontFamily: 'Kanit' }}>{`ประเภทความพิการ`}</div>
              <div style={{ fontSize: '1.10rem', color: '#333', fontFamily: 'Kanit' }}> {`จำนวน(คน)`} </div>
            </div>
            {
              school?.disability
                ?.sort((a, b) => b.value - a.value)
                .map(x => (
                  <>
                    <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', display: 'flex', justifyItems: 'center' }}>
                      <div style={{ fontSize: '1rem', color: '#333', fontFamily: 'Kanit', marginLeft: 12 }}>{x.name}</div>
                      <div style={{ fontSize: '1rem', color: '#FAAB01', fontFamily: 'Kanit' }}> {x.value.toLocaleString('en-US')} </div>
                    </div>
                    <Divider style={{ margin: 0 }} />
                  </>)
                )
            }

          </div>
        </div>
        <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '380px' }} >
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '72px' }}> </div>
            <div>
              <Title>จำนวนนักเรียนพิการแบ่งตามประเภท</Title>
              <Title fontSize={"0.8rem"} > ปีการศึกษา {filter.year}</Title>
            </div>
            <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                  showModal()
                  setColumns(confixColumnsTable.disabled)
                  setDataTable(mapDataTable('table2', initData, filter.year))
                  setTitle(`ประเภทนักเรียนพิการ ปีการศึกษา${filter.year}`)
                }} />
              </div>
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                  onClick={() => {
                    setQinfo({
                      description: `ข้อมูลนักเรียนพิการในปีการศึกษา 2563-2564 ซึ่งเด็กแต่ละคนถูกจำแนกออกตามเพศ ไม่ว่าจะเป็นเพศหญิง หรือเพศชาย`,
                      refs: [
                        'จำนวนนักเรียนพิการจากข้อมูล DMC ปีการศึกษา 2563-2564',
                      ]
                    });
                    setIsModalQinfoVisible(true)
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: 28 }}>
            <Chart
              options={{
                title: '',
                type: 'stackedAndColumn',
                categories: [
                  'การได้ยิน(หูหนวก)',
                  'สติปัญญา',
                  'พฤติกรรมหรืออารมณ์',
                  'ออทิสติก',
                  'การพูดและภาษา',
                  'การมองเห็น (บอด)',
                  'การเรียนรู้',
                  'ร่างกายหรือสุขภาพ',
                  'ความพิการซ้อน'
                ],
                yAxis: {
                  title: {
                    text: 'จำนวนนักเรียน (คน)'
                  }
                }
              }}
              click={({ e, category, name }) => {
                showModal()
                setColumns(confixColumnsTable.disabled)
                setDataTable(mapDataTable('table2', initData, filter.year))
                setTitle(`ประเภทนักเรียนพิการ ปีการศึกษา${filter.year}`)
              }}
              containerProps={{ style: { height: "260px" } }}
              style={{ width: '100px', height: '320px' }}
              data={school.studentJonAndExjonProvince}
            />
          </div>
        </div>
        <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '380px' }} >
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '72px' }}> </div>
            <div>
              <Title>จำนวนนักเรียนพิการแบ่งตามช่วงชั้น</Title>
              <Title fontSize={"0.8rem"} > ปีการศึกษา {filter.year}</Title>
            </div>
            <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                  showModal()
                  setColumns(confixColumnsTable.disabilityByClass)
                  setDataTable(mapDataTable('table3', initData, filter.year))
                  setTitle(`จำนวนนักเรียนแบ่งตามช่วงชั้น ปีการศึกษา${filter.year}`)
                }} />
              </div>
              <div style={{ width: '24px', justifyContent: 'space-around' }} >
                <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                  onClick={() => {
                    setQinfo({
                      description: ` ข้อมูลนักเรียนพิการในปีการศึกษา 2561 ซึ่งนอกเหนือจากการจำแนกนักเรียนพิการออกตามเพศแล้ว ยังสามารถจำแนกตามระดับชั้นของนักเรียนพิการได้อีกด้วย ไม่ว่าจะเป็น ระดับชั้นอนุบาล ประถมศึกษา มัธยมต้น และมัธยมปลาย`,
                      refs: [
                        'จำนวนนักเรียนพิการจากข้อมูล DMC ปีการศึกษา 2563-2564',
                      ]
                    });
                    setIsModalQinfoVisible(true)
                  }}
                />
              </div>
            </div>
          </div>


          <div style={{ marginTop: 28 }}>
            <Chart
              containerProps={{ style: { height: "260px" } }}
              style={{ width: '100px', height: '260px' }}
              options={{
                title: '',
                type: 'column',
                // categories: ['ก่อนประถมศึกษา', 'ประถมศึกษา', 'มัธยมต้น', 'มัธยมปลาย'],
                yAxis: {
                  title: {
                    text: 'จำนวนนักเรียน (คน)'
                  }
                }
              }}
              data={school.disabilityByClass}
              click={({ e, category, name }) => {
                showModal()
                setColumns(confixColumnsTable.disabilityByClass)
                setDataTable(mapDataTable('table3', initData, filter.year))
                setTitle(`จำนวนนักเรียนพิการแบ่งตามช่วงชั้น ปีการศึกษา${filter.year}`)
              }}
            />

          </div>
        </div>
      </>
    },
  ]

  const handleChangeTab = (event, newValue) => {
    setFilter({
      ...filter, ...{
        view: 'province',
        city_name: null,
        tumbon_name: null,
      }
    })
    setMapType("ทั้งหมด")
    setTabValue(newValue);

  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onclickBorder = (layer, filterBorder) => {
    // if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      } else if (layer === 'tumbon' && filterBorder.tumbon) {
        _filter['view'] = 'tumbon'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = filterBorder.tumbon
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === 'province') return;
    if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'tumbon') {
      _filter['view'] = 'city'
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  return (
    <Layout Role={['eef', 'partner_province']}>
      {
        filter.view === 'sungkud' ?
          <ModalTabelBySungkud
            title={title}
            isModalVisible={isModalVisible}
            dataSource={dataTable}
            columns={columns}
            handleOk={handleOk}
            handleCancel={handleCancel}
            rowSpan={2}
          />
          :
          <ModalTabelByProvicce
            title={title}
            isModalVisible={isModalVisible}
            dataSource={dataTable}
            columns={columns}
            handleOk={handleOk}
            handleCancel={handleCancel}
            rowSpan={2}
          />
      }
      < ModalQinfo
        refs={qinfo.refs}
        description={qinfo.description}
        isModalVisible={isModalQinfoVisible}
        handleCancel={() => {
          setIsModalQinfoVisible(false)
          setQinfo({
            description: '',
            refs: []
          });
        }}
        handleOk={() => {
          setIsModalQinfoVisible(false)
          setQinfo({
            description: '',
            refs: []
          });
        }}
      />
      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            filter={filter}
            data={data_map}
            // lng={99}
            // lat={13.4}
            // zoom={5.2}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
          />
          <div className={`absolute top-[90px] left-2 z-1}`}>
            <div className={`w-[330px] h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-6 transform duration-700 ${open_map_filter ? 'ml-[0]' : 'ml-[-340px]'}`}>
              <span className='font-bold text-lg mx-1 mb-2'>{tabvalue === 0 ? 'ประเภทนักเรียนด้อยโอกาส' : 'ประเภทนักเรียนพิการ'}</span>
              <Select value={map_type} onChange={(value) => {
                setMapType(value)
              }}>
                {
                  tabvalue === 0 ?
                  (
                    <>
                      <Option value="ทั้งหมด">ทั้งหมด</Option>
                      <Option value="welfareID_abandoned">เด็กถูกทอดทิ้ง/กำพร้า</Option>
                      <Option value="welfareID_aids">ผลกระทบจากเอดส์</Option>
                      <Option value="welfareID_child_abuse">เด็กที่ถูกทำร้ายทารุณ</Option>
                      <Option value="welfareID_detention_juvenile">เด็กในสถานพินิจและคุ้มครองเด็กเยาวชน</Option>
                      <Option value="welfareID_drug">เด็กที่มีปัญหาเกี่ยวกับยาเสพติด</Option>
                      <Option value="welfareID_forced">เด็กถูกบังคับให้ขายเเรงงาน</Option>
                      <Option value="welfareID_jon">เด็กยากจน</Option>
                      <Option value="welfareID_minority">ชนกลุ่มน้อย</Option>
                      {/* <Option value="welfareID_other">อื่นๆ</Option> */}
                      <Option value="welfareID_sex_buiness">เด็กที่อยู่ในธุรกิจทางเพศ</Option>
                      <Option value="welfareID_street_children">เด็กเร่ร่อน</Option>
                    </>
                  ) : (
                    <>
                      <Option value="ทั้งหมด">ทั้งหมด</Option>
                      <Option value="disability_autism">บุคคลออทิสติก</Option>
                      <Option value="disability_behavioral">บุคคลที่มีความบกพร่องทางพฤติกรรมหรืออารมณ์</Option>
                      <Option value="disability_double">บุคคลพิการซ้อน</Option>
                      <Option value="disability_hearing">บุคคลที่มีความบกพร่องทางการได้ยิน</Option>
                      <Option value="disability_intellectually">บุคคลที่มีความบกพร่องทางสติปัญญา</Option>
                      <Option value="disability_learning">บุคคลที่มีความบกพร่องทางการเรียนรู้</Option>
                      <Option value="disability_physically">บุคคลที่มีความบกพร่องทางร่างการหรือสุขภาพ</Option>
                      <Option value="disability_speech">บุคคลที่มีความบกพร่องทางการพูดเเละภาษา</Option>
                      <Option value="disability_visually">บุคคลที่มีความบกพร่องทางการมองเห็น</Option>
                    </>
                  )

                }
              </Select>
            </div>
            <div className='text-center justify-center mt-2 mx-auto'>
              <button class="bg-[#3ebf6a] hover:bg-[#3ebf6a] border-8 border-[#dcfce7] text-white font-bold p-2 rounded-full" onClick={() => setOpenMapFilter(!open_map_filter)}>
                {
                  !open_map_filter ?
                    <FilterAltIcon sx={{ fontSize: 32, color: 'white' }} />
                    : <CloseIcon sx={{ fontSize: 32, color: 'white' }} />
                }
              </button>
              {filter.view !== "province" ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
          </div>
          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนนักเรียนด้อยโอกาส/พิการ</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          title="นักเรียนด้อยโอกาส/พิการ"
          tab={tab_content}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          // titleRef='*ทุนพระกนิษฐา ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2563'
          subjectTitleRef='*ประมวลผล ณ เดือนสิงหาคม 2567'
          handleChangeTab={handleChangeTab}
        />
      </div>
    </Layout >
  );
}